<template>
	<div class="box">
		<div class="title_box flex">
			<div class="flex">
				<h3
					:class="$store.state.ApplyForInvoiceTitle == '申请发票' ? 'TitleHover' : 'h3'"
					@click="TitleShow('申请发票')"
				>
					申请发票
				</h3>
				<h3
					:class="$store.state.ApplyForInvoiceTitle == '我的开票资料' ? 'TitleHover' : 'h3'"
					@click="TitleShow('我的开票资料')"
				>
					我的开票资料
				</h3>
			</div>
			<div class="flex">
				<button @click="goAddInvoice" class="add_but">
					添加开票资料
				</button>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	components: {},
	computed:{
		...mapState(['ApplyForInvoiceTitle'])
	},
	mounted() {
	},
	data() {
		return {
			title: '申请发票',
		}
	},
	methods: {
		TitleShow(item) {
			this.$store.state.ApplyForInvoiceTitle = item
			if (this.$store.state.ApplyForInvoiceTitle == '申请发票') {
				this.$router.push({
					path: '/user/ApplyForInvoice/ApplyForInvoiceData',
				})
			} else {
				this.$router.push({
					path: '/user/ApplyForInvoice/ApplyForInvoiceList',
				})
			}
		},
		// 跳转添加开票资源
		goAddInvoice(){
			this.$router.push({
				path:'/user/AddInvoice'
			})
		}
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/ApplyForInvoice.scss';
</style>